.scrap-rates {
  padding: 20px;
  max-width: 1200px;
  margin: auto;
  font-family: "Arial", sans-serif; /* Change to your preferred font */
}

.note {
  margin: 20px 0;
  font-size: 1rem;
  color: #555;
  text-align: left;
  line-height: 1.5; /* Improved spacing */
}

.static-content {
  margin: 20px 0;
  padding: 15px;
  background-color: #f1f1f1; /* Light background for contrast */
  border-radius: 8px;
}

.static-content h2 {
  font-size: 1.5rem;
  color: #333;
}

.static-content p {
  margin: 10px 0;
  font-size: 1rem;
  color: #666;
  line-height: 1.6; /* Improved spacing */
  text-align: left;
}

.scrap-rates-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.scrap-card {
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.scrap-card:hover {
  transform: scale(1.05);
}

.rate {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
}

.note,
.min-weight,
.description {
  margin: 5px 0;
  font-size: 0.9rem;
  color: #666;
}

.book-button {
  margin-top: auto; /* Aligns button to the bottom */
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #4caf50;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: bold;
}

.book-button:hover {
  background-color: #45a049;
}
