body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.vercel-insights-badge,
.vercel-badge {
  display: none !important;
}

.toolbar_toolbarContainer__BtQnv,
.geist-dark,
.bubbles_bubbleContainerInner__fHHMT,
.bubbles_shown__O_4hy,
.tooltip_wrapper__vA_JM {
  display: none !important;
}

html .tooltip_wrapper__vA_JM {
  display: none !important;
}
