.pollution-section {
  padding: 4rem 0;
  background-color: #f8f8f8;
  text-align: center;
}

.content-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
}

.info-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  flex: 1;
  max-width: 300px;
  text-align: center;
}

.info-icon {
  width: 100px;
  height: 100px;
  margin-bottom: 1rem;
}

.info-title {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.info-text {
  font-size: 1rem;
  color: #555;
  text-align: left;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .content-container {
    flex-direction: column;
    align-items: center;
  }

  .info-card {
    max-width: 90%;
  }
}

@media (max-width: 480px) {
  .info-title {
    font-size: 1.5rem;
  }

  .info-text {
    font-size: 0.9rem;
  }

  .info-icon {
    width: 80px;
    height: 80px;
  }
}
