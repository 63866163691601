/* Global Styling */
body,
html {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
  overflow-x: hidden;
}

/* Home Container */
.home-container {
  width: 100%;
  height: 100%;
}

/* Hero Section */
.hero-section {
  display: flex;
  position: relative;
  /* min-height: 100vh; */
  justify-content: space-between;
  align-items: center;
  /* background-color: #f8f8f8; */
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-size: contain;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.5); */
}

.content {
  position: relative;
  z-index: 2;
  max-width: 800px;
  text-align: center;
  padding: 20px;
  margin-left: 3rem;
  /* color: white; */
}

.hero-title {
  font-size: 3.5rem;
  font-weight: 600;
  line-height: 1.2;
  text-align: left;
  margin-bottom: 20px;
}

.highlight {
  color: #00d1b2;
}

.sub-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  /* color: #e0e0e0; */
}

.download-link {
  color: #00d1b2;
  cursor: pointer;
  text-decoration: none;
  font-weight: 600;
}

.download-link:hover {
  color: #00c6ff;
}

.cta-button {
  margin-top: 25px;
  padding: 15px 40px;
  font-size: 1.2rem;
  font-weight: 700;
  background-color: #00d1b2;
  color: white;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.cta-button:hover {
  background-color: #00b09b;
  transform: scale(1.05);
}

/* Left-side content */
.left-side-content {
  position: relative;
  z-index: 2;
  width: 20%;
  color: white;
  padding: 30px;
  text-align: left;
  font-size: 1.2rem;
  line-height: 2rem;
  background-color: rgba(0, 0, 0, 0.7);
}

.left-side-content h2 {
  color: #00d1b2;
  font-size: 2rem;
}

.left-side-content ul {
  list-style-type: none;
  padding: 0;
}

.left-side-content li {
  margin: 10px 0;
}

/* Right-side content */
.right-side-content {
  position: relative;
  z-index: 2;
  width: 20%;
  padding: 30px;
  display: flex;
  justify-content: center;
}

.rvm-image {
  width: 100%;
  max-width: 300px;
  height: auto;
}

/* Feature Section */
.feature-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 50px;
  padding: 80px 20px;
  /* background-color: #f9f9f9; */
}

.feature-card {
  background-color: white;
  padding: 25px;
  border-radius: 20px;
  text-align: center;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-12px);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2);
}

.icon {
  font-size: 3rem;
  color: #00d1b2;
  margin-bottom: 15px;
}

.feature-card h3 {
  font-size: 1.6rem;
  margin-bottom: 10px;
}

.feature-card p {
  font-size: 1.1rem;
  color: #666;
}

/* Media Queries for Responsive Design */
@media (max-width: 1200px) {
  .hero-title {
    font-size: 3rem;
  }
  .cta-button {
    font-size: 1.1rem;
  }

  .left-side-content,
  .right-side-content {
    width: 25%;
  }
}

@media (max-width: 768px) {
  .hero-section {
    display: block;
  }
  .hero-title {
    font-size: 2.5rem;
  }
  .cta-button {
    font-size: 1rem;
    padding: 12px 30px;
  }

  .left-side-content,
  .right-side-content {
    width: 30%;
  }
}

@media (max-width: 480px) {
  .hero-section {
    display: block;
  }
  .hero-title {
    font-size: 2rem;
  }
  .cta-button {
    font-size: 0.9rem;
    padding: 10px 25px;
  }
  .content {
    padding: 15px;
  }
  .feature-section {
    padding: 40px 10px;
    gap: 20px;
  }

  .left-side-content,
  .right-side-content {
    display: none;
  }
}
