/* Container */
.rvm-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: "Roboto", sans-serif; /* Use Roboto for body text */
  color: #333;
}

/* Header Section */
.rvm-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-bottom: 50px;
}
.rvm-header-text {
  flex-basis: 95%;
  text-align: left;
}
.rvm-header-text h1 {
  font-family: "Poppins", sans-serif; /* Use Poppins for headings */
  font-size: 2.5em;
  color: #333;
}

.rvm-header-text p {
  font-size: 1.1em;
  color: #666;
}

/* Header Image */
.rvm-header-image img {
  width: 100%;
  height: auto;
  max-width: 800px;
}

/* How it Works */
.how-it-works {
  margin-bottom: 40px;
}

.how-it-works h2 {
  font-family: "Poppins", sans-serif; /* Use Poppins for headings */
  font-size: 2em;
  color: #4caf50;
}

.how-it-works-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.how-it-works-text p {
  font-size: 1.1em;
  color: #666;
}

/* Features Section */
.rvm-features {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 30px;
}

.feature-item {
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.feature-item:hover {
  transform: translateY(-10px);
}

.feature-icon {
  font-size: 3em;
  color: #4caf50;
  margin-bottom: 15px;
}

.feature-item h3 {
  font-family: "Poppins", sans-serif; /* Use Poppins for headings */
  font-size: 1.4em;
  margin-bottom: 10px;
}

.feature-item p {
  font-size: 1.1em;
  color: #555;
}

/* Paragraph Style */
p {
  text-align: left;
}

/* Locations Section */
.rvm-locations {
  margin-top: 40px;
  text-align: center;
  margin-bottom: 30px;
}

.rvm-locations h2 {
  font-family: "Poppins", sans-serif; /* Use Poppins for headings */
  font-size: 1.8em;
  color: #4caf50;
  margin-bottom: 20px;
}

.rvm-locations-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 30px;
  justify-items: center;
}

.rvm-location-item {
  text-align: center;
}

.rvm-location-item p {
  font-size: 1.1em;
  color: #333;
  margin-top: 10px;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .rvm-header {
    flex-direction: column;
    text-align: center;
  }

  .rvm-header-image img {
    max-width: 500px;
  }

  .how-it-works-content {
    flex-direction: column;
    text-align: center;
  }
}
