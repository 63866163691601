.leading-brands-container {
  text-align: center;
  background-color: #ffffff;
  padding: 2rem 0;
  position: relative;
  bottom: 3rem;
}

.heading p {
  font-size: 2rem;
  line-height: 8rem;
  margin-bottom: 2rem;
}

.images-wrapper {
  display: flex;
  justify-content: center;
  align-items: center; /* Align both horizontally and vertically */
  width: 100%;
}

.images-container {
  width: 80%; /* Adjust as per your container size */
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(200px, 1fr)
  ); /* Increase min image size */
  gap: 40px; /* Space between images */
  justify-items: center; /* Center images horizontally */
  align-items: center; /* Center images vertically */
}

.images-container img {
  width: 100%;
  max-width: 250px; /* Increase the size of the logos */
  object-fit: contain; /* Ensure images maintain their proportions */
}

/* Media Queries for Responsiveness */
.heading {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
/* Tablets and small screens (768px and below) */
@media (max-width: 768px) {
  .images-container {
    grid-template-columns: repeat(
      auto-fit,
      minmax(150px, 1fr)
    ); /* Adjust size for tablets */
    gap: 30px;
  }

  .heading p {
    font-size: 1.8rem; /* Adjust font size for smaller screens */
  }
}

/* Mobile phones (480px and below) */
@media (max-width: 480px) {
  .images-container {
    grid-template-columns: repeat(2, 1fr); /* Display 2 images per row */
    gap: 15px;
  }

  .heading p {
    font-size: 1.5rem;
  }

  .images-container img {
    max-width: 120px; /* Smaller size for mobile */
  }
}
