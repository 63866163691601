.testimonial-card {
  border: 1px solid #ddd;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%; /* Allow cards to adjust with the grid */
  max-width: 300px; /* Max width to prevent cards from getting too wide */
  margin: 16px;
  overflow: hidden;
}

.testimonial_container {
  display: grid;
  gap: 1rem;
  box-sizing: border-box;
  padding: 1rem;
  grid-template-columns: repeat(4, 1fr); /* 4 columns on larger screens */
  justify-items: center; /* Center cards within the grid */
}

/* Testimonial Header Styling */
.testimonial-header {
  background-color: #3b6b4b; /* Green color */
  color: white;
  display: flex;
  align-items: center;
  padding: 16px;
}

.avatar {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-right: 16px;
}

.name {
  font-size: 1.2em;
  font-weight: bold;
}

.testimonial-body {
  padding: 16px;
  background-color: #f9f9f9; /* Light background for the testimonial text */
}

.testimonial-body p {
  margin: 0;
  font-size: 1em;
  text-align: left;
}

/* Media Queries for Responsiveness */

/* Tablets and small screens (768px and below) */
@media (max-width: 768px) {
  .testimonial_container {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on medium screens */
    gap: 1.5rem;
  }
}

/* Mobile phones (480px and below) */
@media (max-width: 480px) {
  .testimonial_container {
    grid-template-columns: repeat(1, 1fr); /* 1 column on small screens */
    gap: 1rem;
  }

  .testimonial-header {
    padding: 12px; /* Reduce padding for smaller screens */
  }

  .testimonial-body {
    padding: 12px;
  }

  .avatar {
    width: 50px;
    height: 50px;
    margin-right: 12px;
  }

  .name {
    font-size: 1.1em;
  }

  .testimonial-body p {
    font-size: 0.9em; /* Reduce font size for better readability on small screens */
  }
}
