#DIV_1 {
    block-size: 553.594px;
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    display: flex;
    height: 553.594px;
    inline-size: 1290px;
    margin-inline-end: -15px;
    margin-inline-start: -15px;
    overflow-wrap: break-word;
    perspective-origin: 645px 276.797px;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    transform-origin: 645px 276.797px;
    width: 1290px;
    border: 0px none rgb(33, 37, 41);
    flex-flow: row wrap;
    font: 16px / 24px Montserrat, sans-serif;
    margin: 0px -15px;
    outline: rgb(33, 37, 41) none 0px;
}/*#DIV_1*/

#DIV_1:after {
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    display: block;
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(33, 37, 41);
    font: 16px / 24px Montserrat, sans-serif;
    outline: rgb(33, 37, 41) none 0px;
}/*#DIV_1:after*/

#DIV_1:before {
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    display: block;
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(33, 37, 41);
    font: 16px / 24px Montserrat, sans-serif;
    outline: rgb(33, 37, 41) none 0px;
}/*#DIV_1:before*/

#DIV_2 {
    block-size: 505.594px;
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    bottom: 0px;
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    height: 505.594px;
    inline-size: 645px;
    inset-block-end: 0px;
    inset-block-start: 0px;
    inset-inline-end: 0px;
    inset-inline-start: 0px;
    left: 0px;
    margin-block-start: 48px;
    max-inline-size: 50%;
    max-width: 50%;
    min-block-size: 1px;
    min-height: 1px;
    min-inline-size: auto;
    min-width: auto;
    overflow-wrap: break-word;
    padding-block-start: 48px;
    padding-inline-end: 15px;
    padding-inline-start: 30px;
    perspective-origin: 322.5px 252.797px;
    position: relative;
    right: 0px;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    top: 0px;
    transform-origin: 322.5px 252.797px;
    width: 645px;
    border: 0px none rgb(33, 37, 41);
    flex: 0 0 50%;
    font: 16px / 24px Montserrat, sans-serif;
    margin: 48px 0px 0px;
    outline: rgb(33, 37, 41) none 0px;
    padding: 48px 15px 0px 30px;
}/*#DIV_2*/

#DIV_2:after {
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(33, 37, 41);
    font: 16px / 24px Montserrat, sans-serif;
    outline: rgb(33, 37, 41) none 0px;
}/*#DIV_2:after*/

#DIV_2:before {
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(33, 37, 41);
    font: 16px / 24px Montserrat, sans-serif;
    outline: rgb(33, 37, 41) none 0px;
}/*#DIV_2:before*/

#H1_3 {
    block-size: 120px;
    border-block-end-color: rgb(17, 24, 39);
    border-block-start-color: rgb(17, 24, 39);
    border-inline-end-color: rgb(17, 24, 39);
    border-inline-start-color: rgb(17, 24, 39);
    box-sizing: border-box;
    caret-color: rgb(17, 24, 39);
    color: rgb(17, 24, 39);
    column-rule-color: rgb(17, 24, 39);
    height: 120px;
    inline-size: 600px;
    letter-spacing: -1.4px;
    margin-block-end: 10px;
    margin-block-start: 20px;
    max-inline-size: 600px;
    max-width: 600px;
    overflow-wrap: break-word;
    perspective-origin: 300px 60px;
    text-align: left;
    text-decoration: none solid rgb(17, 24, 39);
    text-emphasis-color: rgb(17, 24, 39);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    transform-origin: 300px 60px;
    width: 600px;
    border: 0px none rgb(17, 24, 39);
    font: 800 50px / 60px Inter;
    margin: 20px 0px 10px;
    outline: rgb(17, 24, 39) none 0px;
}/*#H1_3*/

#H1_3:after {
    border-block-end-color: rgb(17, 24, 39);
    border-block-start-color: rgb(17, 24, 39);
    border-inline-end-color: rgb(17, 24, 39);
    border-inline-start-color: rgb(17, 24, 39);
    box-sizing: border-box;
    caret-color: rgb(17, 24, 39);
    color: rgb(17, 24, 39);
    column-rule-color: rgb(17, 24, 39);
    letter-spacing: -1.4px;
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(17, 24, 39);
    text-emphasis-color: rgb(17, 24, 39);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(17, 24, 39);
    font: 800 50px / 60px Inter;
    outline: rgb(17, 24, 39) none 0px;
}/*#H1_3:after*/

#H1_3:before {
    border-block-end-color: rgb(17, 24, 39);
    border-block-start-color: rgb(17, 24, 39);
    border-inline-end-color: rgb(17, 24, 39);
    border-inline-start-color: rgb(17, 24, 39);
    box-sizing: border-box;
    caret-color: rgb(17, 24, 39);
    color: rgb(17, 24, 39);
    column-rule-color: rgb(17, 24, 39);
    letter-spacing: -1.4px;
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(17, 24, 39);
    text-emphasis-color: rgb(17, 24, 39);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(17, 24, 39);
    font: 800 50px / 60px Inter;
    outline: rgb(17, 24, 39) none 0px;
}/*#H1_3:before*/

#BR_4 {
    border-block-end-color: rgb(17, 24, 39);
    border-block-start-color: rgb(17, 24, 39);
    border-inline-end-color: rgb(17, 24, 39);
    border-inline-start-color: rgb(17, 24, 39);
    box-sizing: border-box;
    caret-color: rgb(17, 24, 39);
    color: rgb(17, 24, 39);
    column-rule-color: rgb(17, 24, 39);
    letter-spacing: -1.4px;
    overflow-wrap: break-word;
    perspective-origin: 0px 0px;
    text-align: left;
    text-decoration: none solid rgb(17, 24, 39);
    text-emphasis-color: rgb(17, 24, 39);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    transform-origin: 0px 0px;
    border: 0px none rgb(17, 24, 39);
    font: 800 50px / 60px Montserrat, sans-serif;
    outline: rgb(17, 24, 39) none 0px;
}/*#BR_4*/

#BR_4:after {
    border-block-end-color: rgb(17, 24, 39);
    border-block-start-color: rgb(17, 24, 39);
    border-inline-end-color: rgb(17, 24, 39);
    border-inline-start-color: rgb(17, 24, 39);
    box-sizing: border-box;
    caret-color: rgb(17, 24, 39);
    color: rgb(17, 24, 39);
    column-rule-color: rgb(17, 24, 39);
    letter-spacing: -1.4px;
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(17, 24, 39);
    text-emphasis-color: rgb(17, 24, 39);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(17, 24, 39);
    font: 800 50px / 60px Montserrat, sans-serif;
    outline: rgb(17, 24, 39) none 0px;
}/*#BR_4:after*/

#BR_4:before {
    border-block-end-color: rgb(17, 24, 39);
    border-block-start-color: rgb(17, 24, 39);
    border-inline-end-color: rgb(17, 24, 39);
    border-inline-start-color: rgb(17, 24, 39);
    box-sizing: border-box;
    caret-color: rgb(17, 24, 39);
    color: rgb(17, 24, 39);
    column-rule-color: rgb(17, 24, 39);
    letter-spacing: -1.4px;
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(17, 24, 39);
    text-emphasis-color: rgb(17, 24, 39);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(17, 24, 39);
    font: 800 50px / 60px Montserrat, sans-serif;
    outline: rgb(17, 24, 39) none 0px;
}/*#BR_4:before*/

#SPAN_5 {
    border-block-end-color: rgb(17, 24, 39);
    border-block-start-color: rgb(17, 24, 39);
    border-inline-end-color: rgb(17, 24, 39);
    border-inline-start-color: rgb(17, 24, 39);
    box-sizing: border-box;
    caret-color: rgb(17, 24, 39);
    color: rgb(17, 24, 39);
    column-rule-color: rgb(17, 24, 39);
    letter-spacing: -1.4px;
    overflow-wrap: break-word;
    perspective-origin: 0px 0px;
    text-align: left;
    text-decoration: none solid rgb(17, 24, 39);
    text-emphasis-color: rgb(17, 24, 39);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    transform-origin: 0px 0px;
    background: rgba(0, 0, 0, 0) linear-gradient(90deg, rgb(10, 155, 26) 0px, rgb(23, 113, 140)) repeat scroll 0% 0% / auto padding-box text;
    border: 0px none rgb(17, 24, 39);
    font: 800 50px / 60px Inter;
    outline: rgb(17, 24, 39) none 0px;
}/*#SPAN_5*/

#SPAN_5:after {
    border-block-end-color: rgb(17, 24, 39);
    border-block-start-color: rgb(17, 24, 39);
    border-inline-end-color: rgb(17, 24, 39);
    border-inline-start-color: rgb(17, 24, 39);
    box-sizing: border-box;
    caret-color: rgb(17, 24, 39);
    color: rgb(17, 24, 39);
    column-rule-color: rgb(17, 24, 39);
    letter-spacing: -1.4px;
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(17, 24, 39);
    text-emphasis-color: rgb(17, 24, 39);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(17, 24, 39);
    font: 800 50px / 60px Inter;
    outline: rgb(17, 24, 39) none 0px;
}/*#SPAN_5:after*/

#SPAN_5:before {
    border-block-end-color: rgb(17, 24, 39);
    border-block-start-color: rgb(17, 24, 39);
    border-inline-end-color: rgb(17, 24, 39);
    border-inline-start-color: rgb(17, 24, 39);
    box-sizing: border-box;
    caret-color: rgb(17, 24, 39);
    color: rgb(17, 24, 39);
    column-rule-color: rgb(17, 24, 39);
    letter-spacing: -1.4px;
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(17, 24, 39);
    text-emphasis-color: rgb(17, 24, 39);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(17, 24, 39);
    font: 800 50px / 60px Inter;
    outline: rgb(17, 24, 39) none 0px;
}/*#SPAN_5:before*/

#DIV_6 {
    block-size: 97.5938px;
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    height: 97.5938px;
    inline-size: 600px;
    overflow-wrap: break-word;
    perspective-origin: 300px 48.7969px;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    transform-origin: 300px 48.7969px;
    width: 600px;
    border: 0px none rgb(33, 37, 41);
    font: 16px / 24px Montserrat, sans-serif;
    outline: rgb(33, 37, 41) none 0px;
}/*#DIV_6*/

#DIV_6:after {
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(33, 37, 41);
    font: 16px / 24px Montserrat, sans-serif;
    outline: rgb(33, 37, 41) none 0px;
}/*#DIV_6:after*/

#DIV_6:before {
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(33, 37, 41);
    font: 16px / 24px Montserrat, sans-serif;
    outline: rgb(33, 37, 41) none 0px;
}/*#DIV_6:before*/

#SPAN_7 {
    border-block-end-color: rgb(91, 91, 91);
    border-block-start-color: rgb(91, 91, 91);
    border-inline-end-color: rgb(91, 91, 91);
    border-inline-start-color: rgb(91, 91, 91);
    box-sizing: border-box;
    caret-color: rgb(91, 91, 91);
    color: rgb(91, 91, 91);
    column-rule-color: rgb(91, 91, 91);
    letter-spacing: -1.2px;
    overflow-wrap: break-word;
    perspective-origin: 0px 0px;
    text-align: left;
    text-decoration: none solid rgb(91, 91, 91);
    text-emphasis-color: rgb(91, 91, 91);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    transform-origin: 0px 0px;
    border: 0px none rgb(91, 91, 91);
    font: 700 19.2px / 28.8px Inter;
    outline: rgb(91, 91, 91) none 0px;
}/*#SPAN_7*/

#SPAN_7:after {
    border-block-end-color: rgb(91, 91, 91);
    border-block-start-color: rgb(91, 91, 91);
    border-inline-end-color: rgb(91, 91, 91);
    border-inline-start-color: rgb(91, 91, 91);
    box-sizing: border-box;
    caret-color: rgb(91, 91, 91);
    color: rgb(91, 91, 91);
    column-rule-color: rgb(91, 91, 91);
    letter-spacing: -1.2px;
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(91, 91, 91);
    text-emphasis-color: rgb(91, 91, 91);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(91, 91, 91);
    font: 700 19.2px / 28.8px Inter;
    outline: rgb(91, 91, 91) none 0px;
}/*#SPAN_7:after*/

#SPAN_7:before {
    border-block-end-color: rgb(91, 91, 91);
    border-block-start-color: rgb(91, 91, 91);
    border-inline-end-color: rgb(91, 91, 91);
    border-inline-start-color: rgb(91, 91, 91);
    box-sizing: border-box;
    caret-color: rgb(91, 91, 91);
    color: rgb(91, 91, 91);
    column-rule-color: rgb(91, 91, 91);
    letter-spacing: -1.2px;
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(91, 91, 91);
    text-emphasis-color: rgb(91, 91, 91);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(91, 91, 91);
    font: 700 19.2px / 28.8px Inter;
    outline: rgb(91, 91, 91) none 0px;
}/*#SPAN_7:before*/

#P_8 {
    block-size: 28.7969px;
    border-block-end-color: rgb(34, 34, 34);
    border-block-start-color: rgb(34, 34, 34);
    border-inline-end-color: rgb(34, 34, 34);
    border-inline-start-color: rgb(34, 34, 34);
    box-sizing: border-box;
    caret-color: rgb(34, 34, 34);
    color: rgb(34, 34, 34);
    column-rule-color: rgb(34, 34, 34);
    height: 28.7969px;
    inline-size: 600px;
    margin-block-end: 16px;
    margin-block-start: 0px;
    overflow-wrap: break-word;
    perspective-origin: 300px 14.3906px;
    text-align: left;
    text-decoration: none solid rgb(34, 34, 34);
    text-emphasis-color: rgb(34, 34, 34);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    transform-origin: 300px 14.3984px;
    width: 600px;
    border: 0px none rgb(34, 34, 34);
    font: 700 19.2px / 28.8px Montserrat, sans-serif;
    margin: 0px 0px 16px;
    outline: rgb(34, 34, 34) none 0px;
}/*#P_8*/

#P_8:after {
    border-block-end-color: rgb(34, 34, 34);
    border-block-start-color: rgb(34, 34, 34);
    border-inline-end-color: rgb(34, 34, 34);
    border-inline-start-color: rgb(34, 34, 34);
    box-sizing: border-box;
    caret-color: rgb(34, 34, 34);
    color: rgb(34, 34, 34);
    column-rule-color: rgb(34, 34, 34);
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(34, 34, 34);
    text-emphasis-color: rgb(34, 34, 34);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(34, 34, 34);
    font: 700 19.2px / 28.8px Montserrat, sans-serif;
    outline: rgb(34, 34, 34) none 0px;
}/*#P_8:after*/

#P_8:before {
    border-block-end-color: rgb(34, 34, 34);
    border-block-start-color: rgb(34, 34, 34);
    border-inline-end-color: rgb(34, 34, 34);
    border-inline-start-color: rgb(34, 34, 34);
    box-sizing: border-box;
    caret-color: rgb(34, 34, 34);
    color: rgb(34, 34, 34);
    column-rule-color: rgb(34, 34, 34);
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(34, 34, 34);
    text-emphasis-color: rgb(34, 34, 34);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(34, 34, 34);
    font: 700 19.2px / 28.8px Montserrat, sans-serif;
    outline: rgb(34, 34, 34) none 0px;
}/*#P_8:before*/

#A_9 {
    border-block-end-color: rgb(0, 114, 198);
    border-block-start-color: rgb(0, 114, 198);
    border-inline-end-color: rgb(0, 114, 198);
    border-inline-start-color: rgb(0, 114, 198);
    box-sizing: border-box;
    caret-color: rgb(0, 114, 198);
    color: rgb(0, 114, 198);
    column-rule-color: rgb(0, 114, 198);
    overflow-wrap: break-word;
    perspective-origin: 0px 0px;
    text-align: left;
    text-decoration: none solid rgb(0, 114, 198);
    text-emphasis-color: rgb(0, 114, 198);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    touch-action: manipulation;
    transform-origin: 0px 0px;
    border: 0px none rgb(0, 114, 198);
    font: 700 19.2px / 28.8px Montserrat, sans-serif;
    outline: rgb(0, 114, 198) none 0px;
}/*#A_9*/

#A_9:after {
    border-block-end-color: rgb(0, 114, 198);
    border-block-start-color: rgb(0, 114, 198);
    border-inline-end-color: rgb(0, 114, 198);
    border-inline-start-color: rgb(0, 114, 198);
    box-sizing: border-box;
    caret-color: rgb(0, 114, 198);
    color: rgb(0, 114, 198);
    column-rule-color: rgb(0, 114, 198);
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(0, 114, 198);
    text-emphasis-color: rgb(0, 114, 198);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(0, 114, 198);
    font: 700 19.2px / 28.8px Montserrat, sans-serif;
    outline: rgb(0, 114, 198) none 0px;
}/*#A_9:after*/

#A_9:before {
    border-block-end-color: rgb(0, 114, 198);
    border-block-start-color: rgb(0, 114, 198);
    border-inline-end-color: rgb(0, 114, 198);
    border-inline-start-color: rgb(0, 114, 198);
    box-sizing: border-box;
    caret-color: rgb(0, 114, 198);
    color: rgb(0, 114, 198);
    column-rule-color: rgb(0, 114, 198);
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(0, 114, 198);
    text-emphasis-color: rgb(0, 114, 198);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(0, 114, 198);
    font: 700 19.2px / 28.8px Montserrat, sans-serif;
    outline: rgb(0, 114, 198) none 0px;
}/*#A_9:before*/

#BR_10 {
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    overflow-wrap: break-word;
    perspective-origin: 0px 0px;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    transform-origin: 0px 0px;
    border: 0px none rgb(33, 37, 41);
    font: 16px / 24px Montserrat, sans-serif;
    outline: rgb(33, 37, 41) none 0px;
}/*#BR_10*/

#BR_10:after {
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(33, 37, 41);
    font: 16px / 24px Montserrat, sans-serif;
    outline: rgb(33, 37, 41) none 0px;
}/*#BR_10:after*/

#BR_10:before {
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(33, 37, 41);
    font: 16px / 24px Montserrat, sans-serif;
    outline: rgb(33, 37, 41) none 0px;
}/*#BR_10:before*/

#IMG_11 {
    block-size: 200px;
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    height: 200px;
    inline-size: 363.625px;
    margin-block-start: 10px;
    margin-inline-start: 50px;
    max-block-size: 200px;
    max-height: 200px;
    max-inline-size: 100%;
    max-width: 100%;
    overflow-wrap: break-word;
    perspective-origin: 181.812px 100px;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    transform-origin: 181.812px 100px;
    vertical-align: middle;
    width: 363.625px;
    border: 0px none rgb(33, 37, 41);
    font: 16px / 24px Montserrat, sans-serif;
    margin: 10px 0px 0px 50px;
    outline: rgb(33, 37, 41) none 0px;
}/*#IMG_11*/

#IMG_11:after {
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(33, 37, 41);
    font: 16px / 24px Montserrat, sans-serif;
    outline: rgb(33, 37, 41) none 0px;
}/*#IMG_11:after*/

#IMG_11:before {
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(33, 37, 41);
    font: 16px / 24px Montserrat, sans-serif;
    outline: rgb(33, 37, 41) none 0px;
}/*#IMG_11:before*/

#DIV_12 {
    block-size: 553.594px;
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    bottom: 0px;
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    height: 553.594px;
    inline-size: 645px;
    inset-block-end: 0px;
    inset-block-start: 0px;
    inset-inline-end: 0px;
    inset-inline-start: 0px;
    left: 0px;
    max-inline-size: 50%;
    max-width: 50%;
    min-block-size: 1px;
    min-height: 1px;
    min-inline-size: auto;
    min-width: auto;
    overflow-wrap: break-word;
    padding-inline-end: 15px;
    padding-inline-start: 15px;
    perspective-origin: 322.5px 276.797px;
    position: relative;
    right: 0px;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    top: 0px;
    transform-origin: 322.5px 276.797px;
    width: 645px;
    border: 0px none rgb(33, 37, 41);
    flex: 0 0 50%;
    font: 16px / 24px Montserrat, sans-serif;
    outline: rgb(33, 37, 41) none 0px;
    padding: 0px 15px;
}/*#DIV_12*/

#DIV_12:after {
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(33, 37, 41);
    font: 16px / 24px Montserrat, sans-serif;
    outline: rgb(33, 37, 41) none 0px;
}/*#DIV_12:after*/

#DIV_12:before {
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(33, 37, 41);
    font: 16px / 24px Montserrat, sans-serif;
    outline: rgb(33, 37, 41) none 0px;
}/*#DIV_12:before*/

#DIV_13 {
    align-items: center;
    block-size: 204px;
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    display: flex;
    height: 204px;
    inline-size: 615px;
    justify-content: center;
    overflow-wrap: break-word;
    perspective-origin: 307.5px 102px;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    transform-origin: 307.5px 102px;
    width: 615px;
    border: 0px none rgb(33, 37, 41);
    font: 16px / 24px Montserrat, sans-serif;
    outline: rgb(33, 37, 41) none 0px;
}/*#DIV_13*/

#DIV_13:after {
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    display: block;
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(33, 37, 41);
    font: 16px / 24px Montserrat, sans-serif;
    outline: rgb(33, 37, 41) none 0px;
}/*#DIV_13:after*/

#DIV_13:before {
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    display: block;
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(33, 37, 41);
    font: 16px / 24px Montserrat, sans-serif;
    outline: rgb(33, 37, 41) none 0px;
}/*#DIV_13:before*/

#DIV_14 {
    block-size: 297.781px;
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    height: 297.781px;
    inline-size: 500px;
    margin-block-end: 32px;
    margin-block-start: 48px;
    min-block-size: auto;
    min-height: auto;
    min-inline-size: 500px;
    min-width: 500px;
    overflow-wrap: break-word;
    perspective-origin: 250px 148.891px;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    transform-origin: 250px 148.891px;
    width: 500px;
    border: 0px none rgb(33, 37, 41);
    font: 16px / 24px Montserrat, sans-serif;
    margin: 48px 0px 32px;
    outline: rgb(33, 37, 41) none 0px;
}/*#DIV_14*/

#DIV_14:after {
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(33, 37, 41);
    font: 16px / 24px Montserrat, sans-serif;
    outline: rgb(33, 37, 41) none 0px;
}/*#DIV_14:after*/

#DIV_14:before {
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(33, 37, 41);
    font: 16px / 24px Montserrat, sans-serif;
    outline: rgb(33, 37, 41) none 0px;
}/*#DIV_14:before*/

#DIV_15 {
    backdrop-filter: blur(80px);
    block-size: 297.781px;
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-end-end-radius: 12px;
    border-end-start-radius: 12px;
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    border-start-end-radius: 12px;
    border-start-start-radius: 12px;
    box-shadow: rgb(44, 107, 52) 2px 0px 15px 0px;
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    float: right;
    height: 297.781px;
    inline-size: 500px;
    overflow-wrap: break-word;
    padding-block-end: 16px;
    padding-block-start: 16px;
    padding-inline-end: 32px;
    padding-inline-start: 32px;
    perspective-origin: 250px 148.891px;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    transform-origin: 250px 148.891px;
    width: 500px;
    background: rgb(246, 250, 245) none repeat scroll 0% 0% / auto padding-box border-box;
    border: 0px none rgb(33, 37, 41);
    border-radius: 12px;
    font: 16px / 24px Montserrat, sans-serif;
    outline: rgb(33, 37, 41) none 0px;
    padding: 16px 32px;
}/*#DIV_15*/

#DIV_15:after {
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(33, 37, 41);
    font: 16px / 24px Montserrat, sans-serif;
    outline: rgb(33, 37, 41) none 0px;
}/*#DIV_15:after*/

#DIV_15:before {
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(33, 37, 41);
    font: 16px / 24px Montserrat, sans-serif;
    outline: rgb(33, 37, 41) none 0px;
}/*#DIV_15:before*/

#DIV_16 {
    block-size: 249.781px;
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    height: 249.781px;
    inline-size: 436px;
    overflow-wrap: break-word;
    perspective-origin: 218px 124.891px;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    transform-origin: 218px 124.891px;
    width: 436px;
    border: 0px none rgb(33, 37, 41);
    font: 16px / 24px Montserrat, sans-serif;
    outline: rgb(33, 37, 41) none 0px;
}/*#DIV_16*/

#DIV_16:after {
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(33, 37, 41);
    font: 16px / 24px Montserrat, sans-serif;
    outline: rgb(33, 37, 41) none 0px;
}/*#DIV_16:after*/

#DIV_16:before {
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(33, 37, 41);
    font: 16px / 24px Montserrat, sans-serif;
    outline: rgb(33, 37, 41) none 0px;
}/*#DIV_16:before*/

#FORM_17 {
    block-size: 249.781px;
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    height: 249.781px;
    inline-size: 436px;
    margin-block-end: 0px;
    overflow-wrap: break-word;
    perspective-origin: 218px 124.891px;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    transform-origin: 218px 124.891px;
    width: 436px;
    border: 0px none rgb(33, 37, 41);
    font: 16px / 24px Montserrat, sans-serif;
    margin: 0px;
    outline: rgb(33, 37, 41) none 0px;
}/*#FORM_17*/

#FORM_17:after {
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(33, 37, 41);
    font: 16px / 24px Montserrat, sans-serif;
    outline: rgb(33, 37, 41) none 0px;
}/*#FORM_17:after*/

#FORM_17:before {
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(33, 37, 41);
    font: 16px / 24px Montserrat, sans-serif;
    outline: rgb(33, 37, 41) none 0px;
}/*#FORM_17:before*/

#H4_18 {
    align-items: center;
    block-size: 29px;
    border-block-end-color: rgb(91, 91, 91);
    border-block-start-color: rgb(91, 91, 91);
    border-inline-end-color: rgb(91, 91, 91);
    border-inline-start-color: rgb(91, 91, 91);
    box-sizing: border-box;
    caret-color: rgb(91, 91, 91);
    color: rgb(91, 91, 91);
    column-rule-color: rgb(91, 91, 91);
    display: flex;
    height: 29px;
    inline-size: 436px;
    letter-spacing: -1px;
    margin-block-end: 10px;
    margin-block-start: 0px;
    overflow-wrap: break-word;
    perspective-origin: 218px 14.5px;
    text-align: left;
    text-decoration: none solid rgb(91, 91, 91);
    text-emphasis-color: rgb(91, 91, 91);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    transform-origin: 218px 14.5px;
    width: 436px;
    border: 0px none rgb(91, 91, 91);
    font: 700 18px / 29px Inter;
    margin: 0px 0px 10px;
    outline: rgb(91, 91, 91) none 0px;
}/*#H4_18*/

#H4_18:after {
    border-block-end-color: rgb(91, 91, 91);
    border-block-start-color: rgb(91, 91, 91);
    border-inline-end-color: rgb(91, 91, 91);
    border-inline-start-color: rgb(91, 91, 91);
    box-sizing: border-box;
    caret-color: rgb(91, 91, 91);
    color: rgb(91, 91, 91);
    column-rule-color: rgb(91, 91, 91);
    display: block;
    letter-spacing: -1px;
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(91, 91, 91);
    text-emphasis-color: rgb(91, 91, 91);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(91, 91, 91);
    font: 700 18px / 29px Inter;
    outline: rgb(91, 91, 91) none 0px;
}/*#H4_18:after*/

#H4_18:before {
    border-block-end-color: rgb(91, 91, 91);
    border-block-start-color: rgb(91, 91, 91);
    border-inline-end-color: rgb(91, 91, 91);
    border-inline-start-color: rgb(91, 91, 91);
    box-sizing: border-box;
    caret-color: rgb(91, 91, 91);
    color: rgb(91, 91, 91);
    column-rule-color: rgb(91, 91, 91);
    display: block;
    letter-spacing: -1px;
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(91, 91, 91);
    text-emphasis-color: rgb(91, 91, 91);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(91, 91, 91);
    font: 700 18px / 29px Inter;
    outline: rgb(91, 91, 91) none 0px;
}/*#H4_18:before*/

#P_19 {
    block-size: 17px;
    border-block-end-color: rgb(91, 91, 91);
    border-block-start-color: rgb(91, 91, 91);
    border-inline-end-color: rgb(91, 91, 91);
    border-inline-start-color: rgb(91, 91, 91);
    box-sizing: border-box;
    caret-color: rgb(91, 91, 91);
    color: rgb(91, 91, 91);
    column-rule-color: rgb(91, 91, 91);
    height: 17px;
    inline-size: 436px;
    margin-block-end: 6.4px;
    margin-block-start: 16px;
    overflow-wrap: break-word;
    perspective-origin: 218px 8.5px;
    text-align: left;
    text-decoration: none solid rgb(91, 91, 91);
    text-emphasis-color: rgb(91, 91, 91);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    text-transform: uppercase;
    transform-origin: 218px 8.5px;
    width: 436px;
    border: 0px none rgb(91, 91, 91);
    font: 700 14px / 17px Inter;
    margin: 16px 0px 6.4px;
    outline: rgb(91, 91, 91) none 0px;
}/*#P_19*/

#P_19:after {
    border-block-end-color: rgb(91, 91, 91);
    border-block-start-color: rgb(91, 91, 91);
    border-inline-end-color: rgb(91, 91, 91);
    border-inline-start-color: rgb(91, 91, 91);
    box-sizing: border-box;
    caret-color: rgb(91, 91, 91);
    color: rgb(91, 91, 91);
    column-rule-color: rgb(91, 91, 91);
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(91, 91, 91);
    text-emphasis-color: rgb(91, 91, 91);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    text-transform: uppercase;
    border: 0px none rgb(91, 91, 91);
    font: 700 14px / 17px Inter;
    outline: rgb(91, 91, 91) none 0px;
}/*#P_19:after*/

#P_19:before {
    border-block-end-color: rgb(91, 91, 91);
    border-block-start-color: rgb(91, 91, 91);
    border-inline-end-color: rgb(91, 91, 91);
    border-inline-start-color: rgb(91, 91, 91);
    box-sizing: border-box;
    caret-color: rgb(91, 91, 91);
    color: rgb(91, 91, 91);
    column-rule-color: rgb(91, 91, 91);
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(91, 91, 91);
    text-emphasis-color: rgb(91, 91, 91);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    text-transform: uppercase;
    border: 0px none rgb(91, 91, 91);
    font: 700 14px / 17px Inter;
    outline: rgb(91, 91, 91) none 0px;
}/*#P_19:before*/

#DIV_20 {
    align-items: center;
    block-size: 45px;
    border-block-end-color: rgb(191, 191, 191);
    border-block-end-style: solid;
    border-block-end-width: 1px;
    border-block-start-color: rgb(191, 191, 191);
    border-block-start-style: solid;
    border-block-start-width: 1px;
    border-end-end-radius: 8px;
    border-end-start-radius: 8px;
    border-inline-end-color: rgb(191, 191, 191);
    border-inline-end-style: solid;
    border-inline-end-width: 1px;
    border-inline-start-color: rgb(191, 191, 191);
    border-inline-start-style: solid;
    border-inline-start-width: 1px;
    border-start-end-radius: 8px;
    border-start-start-radius: 8px;
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    display: flex;
    height: 45px;
    inline-size: 436px;
    overflow-wrap: break-word;
    padding-inline-start: 16px;
    perspective-origin: 218px 22.5px;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    transform-origin: 218px 22.5px;
    width: 436px;
    border: 1px solid rgb(191, 191, 191);
    border-radius: 8px;
    font: 500 16px / 24px Inter;
    outline: rgb(33, 37, 41) none 0px;
    padding: 0px 0px 0px 16px;
}/*#DIV_20*/

#DIV_20:after {
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    display: block;
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(33, 37, 41);
    font: 500 16px / 24px Inter;
    outline: rgb(33, 37, 41) none 0px;
}/*#DIV_20:after*/

#DIV_20:before {
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    display: block;
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(33, 37, 41);
    font: 500 16px / 24px Inter;
    outline: rgb(33, 37, 41) none 0px;
}/*#DIV_20:before*/

#SPAN_21 {
    block-size: 24px;
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    display: block;
    height: 24px;
    inline-size: 29.6719px;
    min-block-size: auto;
    min-height: auto;
    min-inline-size: auto;
    min-width: auto;
    overflow-wrap: break-word;
    perspective-origin: 14.8281px 12px;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    transform-origin: 14.8359px 12px;
    width: 29.6719px;
    border: 0px none rgb(33, 37, 41);
    font: 500 16px / 24px Montserrat, sans-serif;
    outline: rgb(33, 37, 41) none 0px;
}/*#SPAN_21*/

#SPAN_21:after {
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(33, 37, 41);
    font: 500 16px / 24px Montserrat, sans-serif;
    outline: rgb(33, 37, 41) none 0px;
}/*#SPAN_21:after*/

#SPAN_21:before {
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(33, 37, 41);
    font: 500 16px / 24px Montserrat, sans-serif;
    outline: rgb(33, 37, 41) none 0px;
}/*#SPAN_21:before*/

#INPUT_22 {
    block-size: 43px;
    border-block-end-color: rgb(0, 0, 0);
    border-block-end-style: none;
    border-block-end-width: 0px;
    border-block-start-color: rgb(0, 0, 0);
    border-block-start-style: none;
    border-block-start-width: 0px;
    border-end-end-radius: 8px;
    border-end-start-radius: 8px;
    border-inline-end-color: rgb(0, 0, 0);
    border-inline-end-style: none;
    border-inline-end-width: 0px;
    border-inline-start-color: rgb(0, 0, 0);
    border-inline-start-style: none;
    border-inline-start-width: 0px;
    border-start-end-radius: 8px;
    border-start-start-radius: 8px;
    display: block;
    height: 43px;
    inline-size: 388.328px;
    min-block-size: auto;
    min-height: auto;
    min-inline-size: auto;
    min-width: auto;
    overflow-wrap: break-word;
    padding-inline-start: 9.6px;
    perspective-origin: 194.156px 21.5px;
    text-size-adjust: 100%;
    touch-action: manipulation;
    transform-origin: 194.164px 21.5px;
    width: 388.328px;
    background: rgb(246, 250, 245) none repeat scroll 0% 0% / auto padding-box border-box;
    border: 0px none rgb(0, 0, 0);
    border-radius: 8px;
    font: 500 16px / 24px Inter;
    padding: 1px 2px 1px 9.6px;
}/*#INPUT_22*/

#INPUT_22:after {
    box-sizing: border-box;
    overflow-wrap: break-word;
    text-size-adjust: 100%;
    font: 500 16px / 24px Inter;
}/*#INPUT_22:after*/

#INPUT_22:before {
    box-sizing: border-box;
    overflow-wrap: break-word;
    text-size-adjust: 100%;
    font: 500 16px / 24px Inter;
}/*#INPUT_22:before*/

#P_23 {
    block-size: 10px;
    border-block-end-color: rgb(255, 0, 0);
    border-block-start-color: rgb(255, 0, 0);
    border-inline-end-color: rgb(255, 0, 0);
    border-inline-start-color: rgb(255, 0, 0);
    box-sizing: border-box;
    caret-color: rgb(255, 0, 0);
    color: rgb(255, 0, 0);
    column-rule-color: rgb(255, 0, 0);
    height: 10px;
    inline-size: 436px;
    margin-block-end: 16px;
    margin-block-start: 8px;
    overflow-wrap: break-word;
    perspective-origin: 218px 5px;
    text-align: left;
    text-decoration: none solid rgb(255, 0, 0);
    text-emphasis-color: rgb(255, 0, 0);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    transform-origin: 218px 5px;
    width: 436px;
    border: 0px none rgb(255, 0, 0);
    font: 500 12px / 18px Montserrat, sans-serif;
    margin: 8px 0px 16px;
    outline: rgb(255, 0, 0) none 0px;
}/*#P_23*/

#P_23:after {
    border-block-end-color: rgb(255, 0, 0);
    border-block-start-color: rgb(255, 0, 0);
    border-inline-end-color: rgb(255, 0, 0);
    border-inline-start-color: rgb(255, 0, 0);
    box-sizing: border-box;
    caret-color: rgb(255, 0, 0);
    color: rgb(255, 0, 0);
    column-rule-color: rgb(255, 0, 0);
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(255, 0, 0);
    text-emphasis-color: rgb(255, 0, 0);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(255, 0, 0);
    font: 500 12px / 18px Montserrat, sans-serif;
    outline: rgb(255, 0, 0) none 0px;
}/*#P_23:after*/

#P_23:before {
    border-block-end-color: rgb(255, 0, 0);
    border-block-start-color: rgb(255, 0, 0);
    border-inline-end-color: rgb(255, 0, 0);
    border-inline-start-color: rgb(255, 0, 0);
    box-sizing: border-box;
    caret-color: rgb(255, 0, 0);
    color: rgb(255, 0, 0);
    column-rule-color: rgb(255, 0, 0);
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(255, 0, 0);
    text-emphasis-color: rgb(255, 0, 0);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(255, 0, 0);
    font: 500 12px / 18px Montserrat, sans-serif;
    outline: rgb(255, 0, 0) none 0px;
}/*#P_23:before*/

#ION-BUTTON_24 {
    appearance: button;
    block-size: 50px;
    border-block-end-color: rgb(255, 255, 255);
    border-block-start-color: rgb(255, 255, 255);
    border-end-end-radius: 8px;
    border-end-start-radius: 8px;
    border-inline-end-color: rgb(255, 255, 255);
    border-inline-start-color: rgb(255, 255, 255);
    border-start-end-radius: 8px;
    border-start-start-radius: 8px;
    box-shadow: rgb(37, 116, 48) 3px 4px 12px 0px;
    box-sizing: border-box;
    caret-color: rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    column-rule-color: rgb(255, 255, 255);
    display: inline-block;
    font-family: Montserrat, sans-serif;
    font-kerning: none;
    font-size: 17px;
    font-weight: 500;
    height: 50px;
    inline-size: 150px;
    letter-spacing: 1.02px;
    line-height: 25.5px;
    margin-block-end: 8px;
    margin-block-start: 16px;
    margin-inline-end: 2px;
    margin-inline-start: 2px;
    overflow-wrap: break-word;
    perspective-origin: 75px 25px;
    text-align: center;
    text-decoration: none solid rgb(255, 255, 255);
    text-emphasis-color: rgb(255, 255, 255);
    text-overflow: ellipsis;
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    text-transform: capitalize;
    text-wrap: nowrap;
    transform-origin: 75px 25px;
    user-select: none;
    vertical-align: -webkit-baseline-middle;
    width: 150px;
    background: rgb(58, 179, 74) none repeat scroll 0% 0% / auto padding-box border-box;
    border: 0px none rgb(255, 255, 255);
    border-radius: 8px;
    margin: 16px 2px 8px;
    outline: rgb(255, 255, 255) none 0px;
}/*#ION-BUTTON_24*/

#ION-BUTTON_24:after {
    border-block-end-color: rgb(255, 255, 255);
    border-block-start-color: rgb(255, 255, 255);
    border-inline-end-color: rgb(255, 255, 255);
    border-inline-start-color: rgb(255, 255, 255);
    box-sizing: border-box;
    caret-color: rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    column-rule-color: rgb(255, 255, 255);
    font-family: Montserrat, sans-serif;
    font-kerning: none;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 1.02px;
    line-height: 25.5px;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(255, 255, 255);
    text-emphasis-color: rgb(255, 255, 255);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    text-transform: capitalize;
    text-wrap: nowrap;
    user-select: none;
    border: 0px none rgb(255, 255, 255);
    outline: rgb(255, 255, 255) none 0px;
}/*#ION-BUTTON_24:after*/

#ION-BUTTON_24:before {
    border-block-end-color: rgb(255, 255, 255);
    border-block-start-color: rgb(255, 255, 255);
    border-inline-end-color: rgb(255, 255, 255);
    border-inline-start-color: rgb(255, 255, 255);
    box-sizing: border-box;
    caret-color: rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    column-rule-color: rgb(255, 255, 255);
    font-family: Montserrat, sans-serif;
    font-kerning: none;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 1.02px;
    line-height: 25.5px;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none solid rgb(255, 255, 255);
    text-emphasis-color: rgb(255, 255, 255);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    text-transform: capitalize;
    text-wrap: nowrap;
    user-select: none;
    border: 0px none rgb(255, 255, 255);
    outline: rgb(255, 255, 255) none 0px;
}/*#ION-BUTTON_24:before*/

#P_25 {
    block-size: 22px;
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    height: 22px;
    inline-size: 436px;
    margin-block-end: 16px;
    margin-block-start: 6.4px;
    overflow-wrap: break-word;
    perspective-origin: 218px 11px;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    transform-origin: 218px 11px;
    width: 436px;
    border: 0px none rgb(33, 37, 41);
    font: italic 14px / 21px Inter;
    margin: 6.4px 0px 16px;
    outline: rgb(33, 37, 41) none 0px;
}/*#P_25*/

#P_25:after {
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(33, 37, 41);
    font: italic 14px / 21px Inter;
    outline: rgb(33, 37, 41) none 0px;
}/*#P_25:after*/

#P_25:before {
    border-block-end-color: rgb(33, 37, 41);
    border-block-start-color: rgb(33, 37, 41);
    border-inline-end-color: rgb(33, 37, 41);
    border-inline-start-color: rgb(33, 37, 41);
    box-sizing: border-box;
    caret-color: rgb(33, 37, 41);
    color: rgb(33, 37, 41);
    column-rule-color: rgb(33, 37, 41);
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(33, 37, 41);
    text-emphasis-color: rgb(33, 37, 41);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(33, 37, 41);
    font: italic 14px / 21px Inter;
    outline: rgb(33, 37, 41) none 0px;
}/*#P_25:before*/

#A_26 {
    border-block-end-color: rgb(58, 179, 74);
    border-block-start-color: rgb(58, 179, 74);
    border-inline-end-color: rgb(58, 179, 74);
    border-inline-start-color: rgb(58, 179, 74);
    box-sizing: border-box;
    caret-color: rgb(58, 179, 74);
    color: rgb(58, 179, 74);
    column-rule-color: rgb(58, 179, 74);
    overflow-wrap: break-word;
    perspective-origin: 0px 0px;
    text-align: left;
    text-decoration: none solid rgb(58, 179, 74);
    text-emphasis-color: rgb(58, 179, 74);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    touch-action: manipulation;
    transform-origin: 0px 0px;
    border: 0px none rgb(58, 179, 74);
    font: italic 14px / 21px Montserrat, sans-serif;
    outline: rgb(58, 179, 74) none 0px;
}/*#A_26*/

#A_26:after {
    border-block-end-color: rgb(58, 179, 74);
    border-block-start-color: rgb(58, 179, 74);
    border-inline-end-color: rgb(58, 179, 74);
    border-inline-start-color: rgb(58, 179, 74);
    box-sizing: border-box;
    caret-color: rgb(58, 179, 74);
    color: rgb(58, 179, 74);
    column-rule-color: rgb(58, 179, 74);
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(58, 179, 74);
    text-emphasis-color: rgb(58, 179, 74);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(58, 179, 74);
    font: italic 14px / 21px Montserrat, sans-serif;
    outline: rgb(58, 179, 74) none 0px;
}/*#A_26:after*/

#A_26:before {
    border-block-end-color: rgb(58, 179, 74);
    border-block-start-color: rgb(58, 179, 74);
    border-inline-end-color: rgb(58, 179, 74);
    border-inline-start-color: rgb(58, 179, 74);
    box-sizing: border-box;
    caret-color: rgb(58, 179, 74);
    color: rgb(58, 179, 74);
    column-rule-color: rgb(58, 179, 74);
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid rgb(58, 179, 74);
    text-emphasis-color: rgb(58, 179, 74);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    border: 0px none rgb(58, 179, 74);
    font: italic 14px / 21px Montserrat, sans-serif;
    outline: rgb(58, 179, 74) none 0px;
}/*#A_26:before*/

