/* Common styles */
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbar {
  font-family: Montserrat, sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  background-color: #f8f9fa;
  width: 100%;
  height: 100px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.navbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.local_header_logo {
  width: 100%;
  height: 80px;
}

/* Default view (desktop) */
.navbar-items {
  display: flex;
  margin-left: 2rem;
  flex: 1;
  z-index: 10000;
}

.header_ul {
  display: flex;
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.header_ul a,
.header_ul li {
  text-decoration: none;
  margin-right: 30px;
  color: #3b6b4b;
  font-size: 16px;
}

.navbar-end {
  display: flex;
  align-items: center;
}

.navbar-btn {
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  margin-left: 15px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.schedule-btn {
  background-color: #4caf50;
  color: white;
}

.login-btn {
  background-color: #007bff;
  color: white;
}

.header_ul li {
  font-size: 1rem !important;
}

/* Burger menu icon */
.burger {
  display: none;
  cursor: pointer;
  flex-direction: column;
  gap: 5px;
}

.burger .line {
  width: 25px;
  height: 3px;
  background-color: #333;
  transition: all 0.3s ease;
}

/* Menu open animation */
.burger .line.open:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.burger .line.open:nth-child(2) {
  opacity: 0;
}

.burger .line.open:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

.navbar-logout {
  display: none;
}
/* Mobile view */
@media (max-width: 768px) {
  .navbar-items {
    display: none;
    position: absolute;
    padding: 2rem;
    top: 60px;
    right: 0;
    background-color: #f8f9fa;
    width: 100%;
    flex-direction: column;
    justify-content: right;
    align-items: center;
    transition: all 0.3s ease;
  }

  .navbar-items.open {
    display: flex;
  }

  .navbar-end {
    display: none;
  }

  .navbar-end-mobile {
    display: flex; /* Show mobile buttons */
    flex-direction: row; /* Stack vertically if needed */
  }

  .header_ul {
    flex-direction: column;
    align-items: center;
  }

  .header_ul li {
    margin-bottom: 20px;
    font-size: 18px;
  }

  .burger {
    display: flex;
    justify-content: end;
    /* position: relative;
    left: 14rem; */
  }

  .local_header_logo {
    width: 100%;
    position: relative;
    right: 1rem;
    height: 40px;
  }
}

@media (max-width: 500px) {
  .burger {
    display: flex;
    justify-content: end;
    /* position: relative; */
    /* left: 10rem; */
  }
}

/* Tablet view (768px to 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
  .navbar-items {
    margin-left: 1rem;
  }

  .header_ul a,
  .header_ul li {
    margin-right: 20px;
    font-size: 14px;
  }

  .navbar-btn {
    font-size: 12px;
    padding: 6px 12px;
  }

  .navbar-end-mobile {
    display: none; /* Hide mobile buttons */
  }
}

/* Large desktop view (1025px and above) */
@media (min-width: 1025px) {
  .navbar {
    padding: 1rem 4rem; /* Increase padding for larger screens */
  }

  .navbar-items {
    margin-left: 3rem; /* Add more space for desktop view */
  }

  .header_ul a,
  .header_ul li {
    margin-right: 40px;
    font-size: 18px; /* Slightly larger font for larger screens */
  }

  .navbar-btn {
    font-size: 16px; /* Larger font size for buttons */
    padding: 10px 20px; /* Increase padding */
  }

  .local_header_logo {
    height: 80px; /* Maintain logo size for larger screens */
  }
}
